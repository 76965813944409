@import "theme.scss"; @import "Base.scss";
.buttonsubmit {
  background: #e81a6e;
  width: 100%;
  padding: 12px 0px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  cursor: pointer;
}
