// dark colors
$bg-primary: #0f172a;
$bg-secondary: #1e293b;
$bg-card: #1e293b;
$bg-mini-card: #342742;
$text-primary: #fff;
$text-secondary: rgb(148 163 184);
$border-primary: #464f5a;

.scoopup {
  --themeMode: ;
  --bgPrimary: #fff;
  --bgSecondary: #f5f3f8;
  --bgSmallCard: #f5f3f8;
  --bgCard: #fff;
  --bgMiniCard: #fff;
  --textPrimary: #363352;
  --textSecondary: #363352;
  --borderPrimary: rgb(221, 216, 230);
  --bgOverlay: rgba(255, 255, 255, 0.8);
}

.scoopup[data-theme="dark"] {
  --themeMode: "dark";
  --bgPrimary: #1e293b;
  --bgSecondary: #0f172a;
  --bgSmallCard: #2a3446;
  --bgCard: #1e293b;
  --bgMiniCard: #2a3446;
  --textPrimary: #fff;
  --textSecondary: rgb(148 163 184);
  --borderPrimary: rgb(248 250 252/0.06);
  --bgOverlay: rgba(0, 0, 0, 0.8);
  background-color: $bg-primary;
  * {
    color-scheme: dark;
  }
}
$theme: var(--themeMode);
